import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import Image from "./image"
import Hidden from "@material-ui/core/Hidden"
import Bar from "./bar"

const AppsPanel = () => {
  return (
    <Grid container spacing={3} style={{ minHeight: 500 }}>
      <Grid
        item={true}
        xs={12}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <Typography variant="h4" color="inherit">
          Nasza dedykowana aplikacja mobilna
        </Typography>
        <Bar />
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 30, marginTop: 20 }}
        >
          Nasza dedykowana aplikacja mobilna angażująca pacjenta w proces leczenia, umówiania
          konsultacji, przeglądania historii zaleceń, recept czy skierowań ułatwia korzystanie
          z usług Drdoc.app. Możliwe jest także stworzenie aplikacji White Label na potrzeby klienta
          lub dołączenie naszych usług do istniejącej aplikacji.
        </Typography>
        {/*<Button*/}
        {/*  variant="outlined"*/}
        {/*  color="primary"*/}
        {/*  size="large"*/}
        {/*  endIcon={<ArrowDownwardIcon />}*/}
        {/*>*/}
        {/*  Download*/}
        {/*</Button>*/}
      </Grid>

      <Hidden xsDown>
        <Grid
          item={true}
          sm={6}
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          style={{ padding: 10 }}
        >
          <div style={{ width: "100%" }}>
            <Image
              alt="Virtual Healthcare for you"
              filename="apps-panel-image.png"
            />
          </div>
        </Grid>
      </Hidden>
    </Grid>
  )
}
export default AppsPanel
