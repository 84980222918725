import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import ServiceCard from "./service-card"
import Bar from "./bar"

const Services = () => {
  let services = [
    {
      id: 1,
      image: "search-doctor-icon.png",
      title: "Wyszukiwanie lekarzy",
      description:
        "Wybór lekarzy z dowolnej dostępnej specjalności dla konsutlacji on-line lub w gabinecie."
    },
    {
      id: 2,
      image: "online-pharmacy-icon.png",
      title: "Obsługa recept",
      description:
        "Łatwy sposób na uzyskanie kolejnych recept cyklicznych bez konieczności rozmowy z lekarzem."
    },
    {
      id: 3,
      image: "consultation-icon.png",
      title: "Konsultacje ad-hoc",
      description:
        "Szybki sposób na uzyskanie niemal natychmiastowej konsutlacji z dyżurującym lekarzem."
    },
    {
      id: 4,
      image: "details-info-icon.png",
      title: "Monitoring",
      description:
        "Ciągłe monitorowanie stanu zdrowia pacjenta, sugestie oraz opieka zdalna. Urządzenia inteligentne."
    },
    {
      id: 5,
      image: "emergency-care-icon.png",
      title: "Wezwanie pomocy",
      description:
        "Możliwość szybkie wezwania pomocy z poziomu aplikacji albo urządzeń inteligentnych."
    },
    {
      id: 6,
      image: "tracking-icon.png",
      title: "Historia leczenia",
      description: "Przechowywanie i udostępnianie historii leczenia w ramach aplikacji."
    }
  ]

  return (
    <Grid
      container
      spacing={3}
      style={{
        textAlign: "center",
        minHeight: 200,
        padding: 10
      }}
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography variant="h5">Nasze usługi</Typography>
        <Bar />
        <Typography variant="subtitle1" style={{ margin: 30 }}>
          W zależności od potrzeb klientów i pacjentów oferujemy szereg usług, które mają na celu
          kompleksową opiekę nad osobami objętymi pakietem medycznym.
        </Typography>
      </Grid>

      {services.map(service => (
        <Grid item xs={12} sm={4} key={service.id}>
          <ServiceCard service={service} />
        </Grid>
      ))}

      {/*<Grid item xs={12}>*/}
      {/*  <Button variant="outlined" color="primary" size="large">*/}
      {/*    Learn More*/}
      {/*  </Button>*/}
      {/*</Grid>*/}
    </Grid>
  )
}
export default Services
